import { Info } from '@air/icons';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

interface PermissionToggleInfoProps {
  text: string;
}

export const PermissionToggleInfo = memo(({ text }: PermissionToggleInfoProps) => (
  <Tooltip label={text}>
    <Info className="size-4 text-grey-10" />
  </Tooltip>
));

PermissionToggleInfo.displayName = 'PermissionToggleInfo';
