import { AirActionTrackingLocation } from '@air/analytics';
import { Board, Clip } from '@air/api/types';
import { treeItem, TreeItemButton } from '@air/component-tree';
import { BoardMove, Check } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';
import pluralize from 'pluralize';
import { useCallback } from 'react';

import { useSingleBoardSearchState } from '~/components/BoardSearch/shared/hooks';
import { BoardSelectLibrary } from '~/components/BoardSelect/BoardSelect';
import { PrivateBoardSelect } from '~/components/BoardSelect/PrivateBoardSelect';
import { MoveItemsConfirmationModal } from '~/components/Modals/MoveItemsConfirmationModal/MoveItemsConfirmationModal';
import { BOARDS_SELECT_ROOT_OPTION } from '~/constants/BoardSearch';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useLibraryPermissionsCache } from '~/hooks/useLibraryPermissionsCache';
import { useMoveItems } from '~/hooks/useMoveItems';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { canCreateBoard, canMoveAssetsToBoard } from '~/utils/permissions/boardPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

export interface MoveItemsModalProps {
  boards?: Board[];
  clips?: Pick<Clip, 'assetId' | 'id' | 'openCommentCount'>[];
  trackLocation: AirActionTrackingLocation;
  onMove?: () => void;
}

export const MoveItemsModal = ({
  onClose,
  boards = [],
  onMove,
  clips = [],
  trackLocation,
}: AirModalProps<MoveItemsModalProps>) => {
  const {
    selectedBoard,
    isBoardSelected,
    onSelectBoard,
    shouldShowSubBoards,
    selectedLibrary,
    onSelectLibrary,
    isLibrarySelected,
  } = useSingleBoardSearchState();
  const [showMoveItemsConfirmationModal] = useAirModal(MoveItemsConfirmationModal);

  const isMovingClips = clips.length > 0;
  const isMovingBoards = boards.length > 0;
  const hasDiscussions = clips.some((clip) => clip.openCommentCount);

  const store = useAirStore();

  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const { getBoardPermissions } = useBoardPermissionsCache();
  const { getLibraryPermissions } = useLibraryPermissionsCache();

  const { moveItems } = useMoveItems({
    clips,
    boards,
    trackLocation,
  });

  // if we're moving clips, we don't allow selecting a library
  const isLocationSelected = isMovingClips ? !!selectedBoard : !!selectedLibrary?.id || !!selectedBoard;

  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });

  const getBoardDisabledMessage = useCallback(
    (board: Board) => {
      const boardPermissions = getBoardPermissions(board.id);

      if (isMovingClips) {
        const currentBoardId = CentralizedBoardSelectors.boardId(store.getState());

        if (currentBoardId === board.id) {
          return `You can't select the current board`;
        }

        if (!canMoveAssetsToBoard(boardPermissions)) {
          return 'You do not have permission to move assets to this board';
        }
      }

      if (isMovingBoards) {
        /**
         * If the board is one of the boards they are trying to move,
         * prevent them from selecting it
         */
        const boardIdsToMove = boards.map((b) => b.id);

        if (boardIdsToMove.includes(board.id)) {
          return `You can't move to a board you're trying to move`;
        }

        /**
         * If the board is already the parent of one of the boards they are trying to move,
         * disable it
         */
        const boardInListIsAParentBoard = boards.find((b) => b.parentId === board.id);

        if (boardInListIsAParentBoard) {
          return `You can't move a board to it's current parent`;
        }

        /**
         * If the board has an ancestor that is one of the boards they are trying to move,
         * prevent them from selecting it
         */
        const ancestorIds = (board.ancestors || []).map((a) => a.id);

        const isMovingSubBoard = boardIdsToMove.find((id) => ancestorIds.includes(id));

        if (isMovingSubBoard) {
          return `You can't move a board to one of its sub-boards`;
        }

        if (!canCreateBoard(boardPermissions)) {
          return 'You do not have permission to move boards to this board';
        }
      }
    },
    [boards, getBoardPermissions, isMovingBoards, isMovingClips, store],
  );

  const isBoardDisabled = useCallback(
    (board: Board) => {
      const boardPermissions = getBoardPermissions(board.id);

      if (isMovingClips) {
        const currentBoardId = CentralizedBoardSelectors.boardId(store.getState());

        if (currentBoardId === board.id) {
          return true;
        }

        if (!canMoveAssetsToBoard(boardPermissions)) {
          return true;
        }

        return false;
      }

      if (isMovingBoards) {
        /**
         * If the board is one of the boards they are trying to move,
         * prevent them from selecting it
         */
        const boardIdsToMove = boards.map((b) => b.id);

        if (boardIdsToMove.includes(board.id)) {
          return true;
        }

        /**
         * If the board is already the parent of one of the boards they are trying to move,
         * disable it
         */
        const boardInListIsAParentBoard = boards.find((b) => b.parentId === board.id);

        if (boardInListIsAParentBoard) {
          return true;
        }

        /**
         * If the board has an ancestor that is one of the boards they are trying to move,
         * prevent them from selecting it
         */
        const ancestorIds = (board.ancestors || []).map((a) => a.id);

        const isMovingSubBoard = boardIdsToMove.find((id) => ancestorIds.includes(id));

        if (isMovingSubBoard) {
          return true;
        }

        if (!canCreateBoard(boardPermissions)) {
          return true;
        }

        return false;
      }

      return false;
    },
    [boards, getBoardPermissions, isMovingBoards, isMovingClips, store],
  );

  const isLibraryDisabled = useCallback(
    (library: BoardSelectLibrary) => {
      const libraryPermissions = getLibraryPermissions(library.id);

      if (isMovingClips) {
        return true;
      }

      if (!canCreateBoard(libraryPermissions)) {
        return true;
      }

      return false;
    },
    [getLibraryPermissions, isMovingClips],
  );

  const getLibraryDisabledMessage = useCallback(
    (library: BoardSelectLibrary) => {
      const libraryPermissions = getLibraryPermissions(library.id);

      if (isMovingClips) {
        return 'You cannot move assets to a library';
      }

      if (!canCreateBoard(libraryPermissions)) {
        return 'You do not have permission to move boards to this library';
      }
    },
    [getLibraryPermissions, isMovingClips],
  );

  const handleMove = useCallback(() => {
    /**
     * If there are discussions on the clips, show the confirmation modal
     * to let the user know that the discussions will be deleted when
     * the clips are moved.
     */
    if (hasDiscussions) {
      showMoveItemsConfirmationModal({
        clips,
        boards,
        selectedBoard,
        selectedLibrary,
      });
      onClose?.();
    } else {
      moveItems.mutate(
        { onMove, selectedBoard, selectedLibrary },
        {
          onSuccess: () => {
            onClose?.();
          },
        },
      );
    }
  }, [
    boards,
    clips,
    hasDiscussions,
    moveItems,
    onClose,
    onMove,
    selectedBoard,
    selectedLibrary,
    showMoveItemsConfirmationModal,
  ]);

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <Modal className="flex flex-col gap-6" dangerouslyBypassFocusLock data-testid="MOVE_ITEMS_MODAL" isOpen>
      <header className="flex items-center justify-between gap-2">
        <ModalTitle>Move to...</ModalTitle>
        <ModalCloseButton onClick={onClose} />
      </header>

      <PrivateBoardSelect
        getBoardDisabledMessage={getBoardDisabledMessage}
        getLibraryDisabledMessage={getLibraryDisabledMessage}
        isBoardDisabled={isBoardDisabled}
        isBoardSelected={isBoardSelected}
        isLibraryDisabled={isLibraryDisabled}
        isLibrarySelected={isLibrarySelected}
        onCreateBoard={
          !isMovingClips && canCreateBoard(workspacePermissions)
            ? () => onSelectBoard(BOARDS_SELECT_ROOT_OPTION)
            : undefined
        }
        onSelectBoard={onSelectBoard}
        onSelectLibrary={onSelectLibrary}
        shouldShowSubBoards={shouldShowSubBoards}
      >
        {!isMovingClips && canCreateBoard(workspacePermissions) && (
          <div
            data-testid="MOVE_ITEMS_MODAL_MOVE_TO_TOP_LEVEL"
            className={treeItem({
              size: 'small',
              state: isBoardSelected(BOARDS_SELECT_ROOT_OPTION) ? 'selected' : 'default',
            })}
          >
            <BoardMove className="size-4 shrink-0" />

            <TreeItemButton onClick={() => onSelectBoard(BOARDS_SELECT_ROOT_OPTION)}>
              Move {pluralize('board', boards.length)} to top level
            </TreeItemButton>

            {isBoardSelected(BOARDS_SELECT_ROOT_OPTION) && (
              <div className="flex size-4 shrink-0 items-center justify-center rounded-full bg-blue-3">
                <Check className="size-3 text-blue-11" />
              </div>
            )}
          </div>
        )}
      </PrivateBoardSelect>

      {moveItems.status === 'error' && <p className="mt-16 text-14 text-flamingo-700">{moveItems.error.message}</p>}

      <footer className="flex items-center justify-end gap-2">
        <Button appearance="ghost" color="grey" onClick={onClose} size="large">
          Cancel
        </Button>
        <Button
          appearance="filled"
          color="blue"
          data-testid="MOVE_ITEM_MODAL_MOVE_BUTTON"
          disabled={!isLocationSelected}
          isLoading={moveItems.isPending}
          onClick={handleMove}
          size="large"
        >
          Move
        </Button>
      </footer>
    </Modal>
  );
};
