import { AirActionTrackingLocation, useTrackAddedClipsToBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { addAssetsToBoardsSuccessAction } from '~/store/centralizedClip/actions';
import { getClipWithoutWorkspaceIdKey } from '~/swr-hooks/useGetClipWithNoWorkspaceId';
import { useRefreshAssetsInAllViews, useRemoveAssetsFromUnusedAssetsView } from '~/utils/mutateUtils/GalleryAssets';

export interface AddAssetsToBoardParams {
  /** The ids of the assets you want to add */
  clipIds: Clip['id'][];
  /** The board you want to add the assets to */
  boards: Pick<Board, 'id' | 'title' | 'ancestors'>[];
  /** Location where this action started */
  trackLocation: AirActionTrackingLocation;
  /** The id of the board you are adding assets from, if any */
  parentBoardId?: Board['id'];
}

export const useAddAssetsToBoards = () => {
  const dispatch = useDispatch();
  const { removeAssetsFromUnusedAssetsView } = useRemoveAssetsFromUnusedAssetsView();
  const { refreshAssetsInAllViews } = useRefreshAssetsInAllViews();
  const queryClient = useQueryClient();
  const { trackAddedClipsToBoard } = useTrackAddedClipsToBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useMutation({
    mutationFn: ({ boards, clipIds }: AddAssetsToBoardParams) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const boardIds = boards.map((b) => b.id);

      return Boards.addClips({
        boardIds: boardIds,
        clipIds: clipIds,
        workspaceId,
      });
    },

    onSuccess: (_data, { boards, clipIds, parentBoardId, trackLocation }) => {
      const boardIds = boards.map((b) => b.id);

      removeAssetsFromUnusedAssetsView({ clipIds });
      for (const board of boards) {
        refreshAssetsInAllViews({ parentBoardId: board.id });
      }

      dispatch(addAssetsToBoardsSuccessAction({ boards, clipIds }));

      queryClient.invalidateQueries({
        queryKey: getClipWithoutWorkspaceIdKey({
          clipId: clipIds[0],
          options: {
            boardId: parentBoardId,
          },
        }),
      });

      trackAddedClipsToBoard({
        boardIds,
        clipIds,
        location: trackLocation,
      });
    },

    onError: (error, { clipIds, boards }) => {
      const boardIds = boards.map((b) => b.id);

      reportErrorToBugsnag({
        error,
        context: 'Failed to add assets to a board',
        metadata: { Data: { clipIds, boardIds } },
      });
    },
  });
};
