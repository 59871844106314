import { Close, Search as SearchIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Input } from '@air/primitive-input';
import { Tooltip } from '@air/primitive-tooltip';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { memo, useCallback, useState } from 'react';

export interface SimpleSearchBarProps {
  name: string;
  onSearchTermChange: (search: string) => void;
  placeholder?: string;
  hideCloseButton?: boolean;
}

/**
 * @description A SearchBar component that does not use ComboBox
 */
export const SimpleSearchBar = memo(
  ({ onSearchTermChange, placeholder, name, hideCloseButton = false }: SimpleSearchBarProps) => {
    const [value, setValue] = useState('');
    // eslint complains about passing inline function, but only this version works with debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnSearchChange = useCallback(debounce(onSearchTermChange, 300), [onSearchTermChange]);

    const hasCloseButton = !!value && !hideCloseButton;

    return (
      <div className="relative">
        <SearchIcon className="absolute left-3 top-3 size-4 text-10 text-grey-9 [&>path]:stroke-[2.5px]" />
        <Input
          data-1p-ignore
          className={classNames('w-full pl-9', hasCloseButton ? 'pr-9' : '')}
          size="large"
          value={value}
          onChange={(evt) => {
            setValue(evt.target.value);
            debouncedOnSearchChange(evt.target.value);
          }}
          placeholder={placeholder}
          name={name}
          data-testid="SIMPLE_SEARCH_BAR_INPUT"
        />
        {hasCloseButton && (
          <Tooltip label="Clear search">
            <IconButton
              className="absolute right-3 top-3"
              appearance="ghost"
              color="grey"
              label="Clear search"
              icon={Close}
              onClick={() => {
                setValue('');
                onSearchTermChange('');
              }}
              size="extra-small"
            />
          </Tooltip>
        )}
      </div>
    );
  },
);

SimpleSearchBar.displayName = 'SimpleSearchBar';
