import { EllipsisVertical } from '@air/next-icons';
import { Button, ButtonVariants } from '@air/primitive-button';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { Spinner } from '@air/primitive-spinner';
import { useToasts } from '@air/provider-toast';
import { tailwindMerge } from '@air/tailwind-variants';
import { RefObject } from 'react';

import { COPY_URL_BUTTON, COPY_URL_FIELD_TEXT } from '~/constants/testIDs';

export interface MenuAction {
  action: () => void;
  text: string;
}

export interface CopyFieldProps {
  copyButtonRef?: RefObject<HTMLButtonElement>;
  url: string | undefined;
  onCopy?: (url: string) => void;
  menuActions?: MenuAction[];
  isDisabled?: boolean;
  buttonColor?: ButtonVariants['color'];
  className?: string;
}

export const CopyField = ({
  buttonColor = 'blue',
  copyButtonRef,
  url,
  menuActions = [],
  onCopy,
  isDisabled,
  className,
}: CopyFieldProps) => {
  const { showToast } = useToasts();

  const copyLink = async () => {
    if (!url || isDisabled) {
      return;
    }

    try {
      await window.navigator.clipboard.writeText(url);

      if (onCopy) {
        onCopy(url);
      }

      showToast('Link copied');
    } catch (_error) {
      showToast('Failed to copy link', { color: 'red' });
    }
  };

  return (
    <div className={tailwindMerge('flex h-12 items-center justify-between rounded border border-grey-5', className)}>
      {!url && !isDisabled && (
        <div className="ml-2">
          <Spinner className="size-5 text-teal-9" />
        </div>
      )}
      <div className="ml-3 flex min-w-0 items-center">
        <span className="truncate text-grey-11" data-testid={COPY_URL_FIELD_TEXT}>
          {url}
        </span>
      </div>
      {/* Need to unset minWidth declared in Box component's BoxProps or share links affect button width */}
      <div className="flex items-center gap-2 pr-2" style={{ minWidth: 'unset' }}>
        <Button
          className="ml-0.5"
          appearance="filled"
          color={buttonColor}
          onClick={copyLink}
          ref={copyButtonRef}
          size="medium"
          disabled={isDisabled}
          data-testid={isDisabled ? `${COPY_URL_BUTTON}-disabled` : COPY_URL_BUTTON}
        >
          Copy
        </Button>
        {menuActions.length > 0 && (
          <DropdownMenu
            trigger={
              <IconButton label="Open menu" icon={EllipsisVertical} size="medium" appearance="filled" color="grey" />
            }
          >
            {renderDropdownItems({
              options: menuActions.map(({ text: label, action: onSelect }) => ({
                id: label,
                label,
                onSelect,
                type: 'item',
              })),
            })}
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};
