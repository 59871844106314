import { Copy } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo } from 'react';

import { COPY_URL_BUTTON } from '~/constants/testIDs';

interface CopyLinkButtonProps {
  onCopied: (url: string) => void;
  shareUrl: string;
  className?: string;
}

export const CopyLinkButton = memo(({ onCopied, shareUrl, className }: CopyLinkButtonProps) => {
  const { showToast } = useToasts();

  const onClick = async () => {
    try {
      await window.navigator.clipboard.writeText(shareUrl);

      onCopied?.(shareUrl);

      showToast('Link copied');
    } catch (_error) {
      showToast('Failed to copy link', { color: 'red' });
    }
  };

  return (
    <Button
      data-testid={COPY_URL_BUTTON}
      appearance="filled"
      color="blue"
      onClick={onClick}
      size="extra-large"
      className={tailwindMerge('gap-1', className)}
      prefix={<Copy className="size-6" />}
    >
      Copy link
    </Button>
  );
});

CopyLinkButton.displayName = 'CopyLinkButton';
