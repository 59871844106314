import type { Board, Clip } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import pluralize from 'pluralize';

import { type BoardSearchOption } from '~/components/BoardSearch/PrivateBoardSearch';
import { type BoardSearchLibrary } from '~/components/BoardSearch/shared/hooks';
import { ToastLink } from '~/components/UI/ToastLink';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useMoveItems } from '~/hooks/useMoveItems';
import { useAddAssetsToBoards } from '~/swr-hooks/boards/useAddAssetsToBoards';

export type MoveItemsConfirmationModalProps = {
  boards: Board[];
  clips?: Pick<Clip, 'assetId' | 'id' | 'openCommentCount'>[];
  selectedBoard: BoardSearchOption | null;
  selectedLibrary?: BoardSearchLibrary;
};

export const MoveItemsConfirmationModal = ({
  boards,
  clips,
  selectedBoard,
  selectedLibrary,
  onClose,
}: AirModalProps<MoveItemsConfirmationModalProps>) => {
  const { showToast } = useToasts();
  const { goToBoardPage } = useGoToBoardPage();
  const addAssetsToBoardMutation = useAddAssetsToBoards();
  const { moveItems } = useMoveItems({ boards, clips, trackLocation: 'move-items-confirmation-modal' });

  return (
    <Modal className="flex flex-col gap-6" dangerouslyBypassFocusLock isOpen>
      <div className="flex flex-col gap-2">
        <ModalTitle>Delete comments?</ModalTitle>
        <ModalDescription>
          There are comments on this asset in the current board, and moving it will delete them. To keep these comments,
          consider adding the asset to another board instead.
        </ModalDescription>
      </div>
      <footer className="flex items-center justify-end gap-2">
        <Button appearance="ghost" color="grey" onClick={onClose} size="large">
          Cancel
        </Button>

        <div className="flex gap-2">
          <Button
            appearance="outline"
            color="grey"
            disabled={!selectedBoard}
            isLoading={addAssetsToBoardMutation.isPending}
            onClick={() => {
              if (selectedBoard) {
                addAssetsToBoardMutation.mutate(
                  {
                    clipIds: clips?.map((clip) => clip.id) || [],
                    boards: [selectedBoard],
                    trackLocation: 'move-items-confirmation-modal',
                  },
                  {
                    onSuccess: (_data, { clipIds, boards }) => {
                      showToast(
                        <>
                          {`${pluralize('item', clipIds.length, true)} added to `}
                          {boards.length === 1 ? (
                            <ToastLink
                              onClick={() =>
                                goToBoardPage({ board: boards[0], trackLocation: 'add-clips-to-board-toast' })
                              }
                            >
                              {boards[0].title}
                            </ToastLink>
                          ) : (
                            <>multiple boards</>
                          )}
                        </>,
                      );

                      onClose?.();
                    },
                  },
                );
              }
            }}
            size="large"
          >
            Add instead
          </Button>
          <Button
            appearance="filled"
            color="red"
            isLoading={moveItems.isPending}
            onClick={() =>
              moveItems.mutate(
                {
                  selectedBoard,
                  selectedLibrary,
                },
                {
                  onSuccess: () => {
                    onClose?.();
                  },
                },
              )
            }
            size="large"
          >
            Delete and move
          </Button>
        </div>
      </footer>
    </Modal>
  );
};
