import { TreeItem, TreeItemButton, TreeItemButtonProps, TreeItemProps } from '@air/component-tree';
import { Check } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import { ComponentProps } from 'react';

export type BoardSelectListItemProps = Pick<ComponentProps<'div'>, 'children'> &
  Pick<TreeItemProps, 'onOpenChange' | 'prefix' | 'suffix'> &
  Pick<TreeItemButtonProps, 'disabled'> & {
    disabledMessage?: string;
    id: string;
    isExpanded?: boolean;
    isSelected?: boolean;
    onSelect?: () => void;
    title: string;
  };

export const BoardSelectListItem = ({
  children,
  disabled,
  disabledMessage,
  id,
  isExpanded,
  isSelected,
  onSelect,
  onOpenChange,
  prefix,
  suffix,
  title,
}: BoardSelectListItemProps) => {
  return (
    <div data-testid="BOARD_SELECT_LIST_ITEM" data-id={id} data-title={title}>
      <TreeItem
        data-id={id}
        onOpenChange={onOpenChange}
        open={isExpanded}
        prefix={prefix}
        size="small"
        state={isSelected ? 'selected' : 'default'}
        suffix={
          <div className="flex items-center gap-1">
            {isSelected && (
              <div className="flex size-4 items-center justify-center rounded-full bg-blue-3">
                <Check className="size-3 text-blue-11" />
              </div>
            )}
            {suffix}
          </div>
        }
      >
        <Tooltip label={disabled && !!disabledMessage ? disabledMessage : undefined}>
          <TreeItemButton className={disabled ? 'opacity-40' : 'opacity-100'} disabled={disabled} onClick={onSelect}>
            {title}
          </TreeItemButton>
        </Tooltip>
      </TreeItem>

      {!!children && isExpanded && <div className="pl-6">{children}</div>}
    </div>
  );
};
