import { ScopedPermissions } from '@air/api';

export const canSeePrivateWorkspaceDetails = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canView;

export const canSeeWorkspaceMembers = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canView;

export const canAddWorkspaceMembers = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageMembers;

export const canRemoveWorkspaceMembers = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageMembers;

export const canChangeWorkspaceMemberRole = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageMembers;

export const canChangeWorkspaceDetails = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canEdit;

export const canSeeWorkspaceIntegrationDetails = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canView;

export const canChangeWorkspaceIntegrationDetails = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canEdit;

export const canSeeCustomFields = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.customField.canView;

export const canCreateCustomFields = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.customField.canCreate;

export const canEditCustomFields = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.customField.canEdit;

export const canDeleteCustomFields = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.customField.canDelete;

export const canViewSavedFilters = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canView;

export const canCreateSavedFilters = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageSavedFilters;

export const canEditSavedFilters = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageSavedFilters;

export const canDeleteSavedFilters = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageSavedFilters;

export const canSeeWorkspaceTags = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.tag.canView;

export const canCreateTag = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.tag.canCreate;

export const canUpdateTagName = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.tag.canEdit;

export const canDeleteTag = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.tag.canDelete;

export const canViewAddOns = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.addOn.canView;
export const canEditAddOns = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.addOn.canEdit;

export const canViewSubscription = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canView;

export const canManageSubscription = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageSubscription;

export const canCreateWorkspaceInviteLink = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageMembers;

export const canDeleteWorkspaceInviteLink = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageMembers;

export const canChangeWorkspaceApprovedDomains = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canManageMembers && !!workspaceContext?.workspace.canEdit;

export const canViewPeople = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.person.canView;
export const canEditPerson = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.person.canEdit;
export const canDeletePerson = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.person.canDelete;

export const canSeePerson = (workspaceContext: ScopedPermissions | undefined) => !!workspaceContext?.person.canView;
