import {
  WorkspaceMemberResponseWithRoles,
  WorkspaceMemberRoleDisplayName,
  WorkspaceMemberType,
  WorkspaceUserRole,
} from '@air/api/types';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useFetchMembers } from '~/swr-hooks/members/useFetchMembers';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { useAccountContext } from './AccountProvider';
import { useWorkspaceRolesContext } from './WorkspaceRolesProvider';

type CurrentWorkspaceMemberProviderProps = PropsWithChildren<object>;

export interface CurrentWorkspaceMemberProviderContextValue {
  currentWorkspaceMember?: WorkspaceMemberResponseWithRoles & {
    role?: WorkspaceUserRole;
    isSysAdmin?: boolean;
    isWorkspaceOwner?: boolean;
  };
}

const defaultValue: CurrentWorkspaceMemberProviderContextValue = {
  currentWorkspaceMember: undefined,
};

const CurrentWorkspaceMemberProviderContext = createContext<CurrentWorkspaceMemberProviderContextValue>(defaultValue);

export const CurrentWorkspaceMemberProvider = ({ children }: CurrentWorkspaceMemberProviderProps) => {
  const { data: members } = useFetchMembers();
  const { data: roles } = useWorkspaceRolesContext();
  const { data: account } = useAccountContext();

  const data = useMemo(() => {
    const currentMember = members?.find((member) => member.accountId === account?.id);

    const role = roles?.find(({ id }) => id === currentMember?.roleId);

    return {
      currentWorkspaceMember: currentMember
        ? {
            ...currentMember,
            role,
            isSysAdmin: role?.type === WorkspaceMemberType.sysadmin,
            isWorkspaceOwner: !role?.isCustom && role?.displayName === WorkspaceMemberRoleDisplayName.WorkspaceOwner,
          }
        : undefined,
    };
  }, [account?.id, members, roles]);

  return (
    <CurrentWorkspaceMemberProviderContext.Provider value={data}>
      {children}
    </CurrentWorkspaceMemberProviderContext.Provider>
  );
};

export const useCurrentWorkspaceMemberContext = () => {
  const context = useContext(CurrentWorkspaceMemberProviderContext);

  if (context === defaultValue) {
    const error = 'CurrentWorkspaceMemberProviderContext used outside of CurrentWorkspaceMemberProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
