import { ShortUrl } from '@air/api';
import { Board, Clip, Form, ShortUrl as IShortUrl, ShortUrlObjectType } from '@air/api/types';
import { matchesAirror, NOT_FOUND } from '@air/errors';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const SHORT_ID_INFO = 'SHORT_ID_INFO';

export const getShortIdInformationKey = ({
  objectId,
  boardContext,
}: {
  objectId: Board['id'] | Clip['id'] | Form['id'] | undefined;
  boardContext: Board['id'] | null | undefined;
}) => {
  // boardContext is returned as null for shared url, but api does not accept null - we have to pass undefined
  // to avoid mismatch between cache keys, we map boardContext to null if it is undefined
  return [SHORT_ID_INFO, { objectId, boardContext: boardContext || null }];
};

interface UseGetShortIdInformationParams {
  id: IShortUrl['id'] | undefined;
  type: ShortUrlObjectType;
  boardContext: Board['id'] | undefined;
  canView: boolean;
  refetchOnWindowFocus?: boolean;
}

export const useGetExistingShortIdInformation = ({
  id,
  type,
  boardContext,
  canView,
  refetchOnWindowFocus = true,
}: UseGetShortIdInformationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  return useQuery({
    queryKey: getShortIdInformationKey({ objectId: id, boardContext }),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return ShortUrl.getExistingShortIdForObject({ workspaceId, id: id!, type, boardContext });
    },
    retry: (failureCount, error) => {
      // if the short url is not found, we don't want to retry
      if (matchesAirror(error, NOT_FOUND)) {
        return false;
      }
      return failureCount < 3;
    },
    enabled: !!id && canView && !!currentWorkspace,
    refetchOnWindowFocus,
  });
};
