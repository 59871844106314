import { Account } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useRouter } from 'next/router';
import { createContext, PropsWithChildren, useContext, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import { useAccountContext } from '~/providers/AccountProvider';
import { FiltersModifiedManuallyProvider } from '~/providers/FiltersModifiedManuallyProvider';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { FilterParamsType } from '~/utils/filters/filtersToUrlParamsUtils';
import { urlQueryToFilterParams } from '~/utils/filters/urlQueryToFilterParams';

export interface FiltersContextValueType {
  /** Filters applied by a user */
  filters: Partial<FilterParamsType>;
  /** Filters specific to a page, that can't be change by a user - e.g. bookmarked on favorited assets page */
  immutableFilters: Partial<FilterParamsType> | undefined;
  /** User account id */
  accountId: Account['id'] | undefined;
  /** Search query */
  search: string | undefined;
}

const defaultValue: FiltersContextValueType = {
  filters: {},
  immutableFilters: {},
  accountId: undefined,
  search: undefined,
};

const FiltersContext = createContext<FiltersContextValueType>(defaultValue);

export type GetImmutableFilters = (params: {
  asPath: string;
  accountId: Account['id'] | undefined;
}) => FilterParamsType;

export interface FiltersProviderProps {
  getImmutableFilters?: GetImmutableFilters;
}

export const FiltersProvider = ({ children, getImmutableFilters }: PropsWithChildren<FiltersProviderProps>) => {
  const { data: account } = useAccountContext();
  const { asPath } = useRouter();
  const filtersValue = useRef<FiltersContextValueType>({ ...defaultValue });

  filtersValue.current = useMemo<FiltersContextValueType>(() => {
    const immutableFilters = getImmutableFilters?.({ asPath, accountId: account?.id }) ?? {};

    const searchParams = typeof window !== 'undefined' ? window.location.search : '';

    /**
     * We do not use query from useRouter, because it contains also boardId, clipId, libraryId etc. - all params from url, not just search params
     */
    const query = new URLSearchParams(searchParams);
    const filters = urlQueryToFilterParams({ query });

    const newFilters = {
      ...filters,
      immutableFilters,
      accountId: account?.id,
    };

    if (isEqual(filtersValue.current, newFilters)) {
      return filtersValue.current;
    } else {
      return newFilters;
    }
  }, [account?.id, asPath, getImmutableFilters]);

  return (
    <FiltersContext.Provider value={filtersValue.current}>
      <FiltersModifiedManuallyProvider>{children}</FiltersModifiedManuallyProvider>
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (context === defaultValue) {
    const error = 'FiltersContext used outside of FiltersProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
