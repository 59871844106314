import { reportErrorToBugsnag } from '@air/utils-error';
import { noop } from 'lodash';
import { createContext, ReactNode, useContext, useMemo, useRef } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';

export interface FiltersModifiedManuallyConetxtType {
  getFiltersModifiedManually: () => boolean;
  setFiltersModifiedManually: (value: boolean) => void;
}

const defaultValue: FiltersModifiedManuallyConetxtType = {
  getFiltersModifiedManually: () => false,
  setFiltersModifiedManually: noop,
};

const FiltersModifiedManuallyContext = createContext<FiltersModifiedManuallyConetxtType>(defaultValue);

export const FiltersModifiedManuallyProvider = ({ children }: { children: ReactNode }) => {
  // set true as default, so that filters can be put in redux on first render
  const filtersModifiedManually = useRef(true);

  const value = useMemo<FiltersModifiedManuallyConetxtType>(
    () => ({
      getFiltersModifiedManually: () => filtersModifiedManually.current,
      setFiltersModifiedManually: (value: boolean) => {
        filtersModifiedManually.current = value;
      },
    }),
    [],
  );

  return <FiltersModifiedManuallyContext.Provider value={value}>{children}</FiltersModifiedManuallyContext.Provider>;
};

export const useFiltersModifiedManuallyContext = () => {
  const context = useContext(FiltersModifiedManuallyContext);

  if (context === defaultValue) {
    const error = 'FiltersModifiedManuallyContext used outside of FiltersModifiedManuallyProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
