import { InfoFilled, Link } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';

import { LINK_NOT_AVAILABLE, NO_SHARE_LINK } from '~/constants/testIDs';

export const NoPermissionToCreateShareLink = () => (
  <div className="flex items-center justify-between">
    <div className="flex items-center">
      <Link className="size-4 text-grey-9" />
      <p className="pl-2 text-16 text-grey-9" data-testid={NO_SHARE_LINK}>
        No shareable link
      </p>
    </div>
    <div className="mr-3" data-testid={LINK_NOT_AVAILABLE}>
      <Tooltip label="You do not have permissions to create this link. Contact your workspace admin for help.">
        <InfoFilled className="size-5 text-grey-9" />
      </Tooltip>
    </div>
  </div>
);
